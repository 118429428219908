@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

/* GENERAL FORMATTING */

html {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-size: 1.25rem;
  width: 100%;
}

input {
  font-family: 'Poppins', sans-serif;
}

button {
  font-family: 'Poppins', sans-serif;
  color: #d8d8d8;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #d1d1d1;
}

.app-content {
  background: #111;
  color: #d8d8d8;
  padding-bottom: 0;
  min-height: calc(100vh - 5.5625rem);
}

.header-spacer {
  height: 3.3125rem;
}

/* <PageHeader /> */

header {
  width: 100%;
  margin-top: 0.5rem;
}

.brand {
  position: absolute;
  display: flex;
  align-items: center;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 0;
  padding: 0.5625rem 0rem 0.5625rem 0.5625rem;
}

h1 .header-name-right {
  color: #cb2b4b;
}

.logo-img {
  height: 3.2074rem;
  width: 3.3125rem;
  padding-top: 0.0625rem;
  margin-left: -0.3125rem;
}

/* <NavBar /> */

.nav-btn {
  position: absolute;
  right: 0;
  width: 1.35rem;
  height: 0.75rem;
  cursor: pointer;
  margin: 1.5rem 0.9rem 0 0;
  z-index: 3;
}

.nav-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 1;
  transition: 0.2s ease-out;
}

.nav-btn-icon {
  display: block;
  position: relative;
  height: 0.15rem;
  background: #d8d8d8;
  transition: background-color 0.2s ease-out;
}

.nav-btn-icon::before {
  position: absolute;
  top: -0.3rem;
  height: 100%;
  width: 100%;
  background: inherit;
  content: '';
  background: #d8d8d8;
  transition: all 0.2s ease-out;
}

.nav-btn-icon::after {
  position: absolute;
  top: 0.3rem;
  height: 100%;
  width: 100%;
  background: inherit;
  content: '';
  background: #d8d8d8;
  transition: all 0.2s ease-out;
}

@media (hover: hover) and (pointer: fine) {
  .nav-btn-icon:hover,
  .nav-btn-icon:hover::before,
  .nav-btn-icon:hover::after {
    background: #cb2b4b;
  }
}

.nav-btn-open > .nav-btn-icon {
  background: transparent;
}

.nav-btn-open > .nav-btn-icon::before {
  transform: rotate(-45deg);
  top: 0;
}

.nav-btn-open > .nav-btn-icon::after {
  transform: rotate(45deg);
  top: 0;
}

.nav-menu {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
  overflow: hidden;
  display: block;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  background: #111;
  margin: 0;
  z-index: 2;
  position: relative;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.nav-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.nav-menu-btn svg {
  margin-right: 0.2rem;
}

.nav-menu-open {
  max-height: 14rem;
}

.nav-menu li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

@media (hover: hover) and (pointer: fine) {
  .nav-menu-btn:hover,
  .nav-menu-btn:hover .nav-icons-primary,
  .nav-menu-btn:hover .nav-icons-secondary {
    color: #cb2b4b;
    fill: #cb2b4b;
  }
}

@media only screen and (min-width: 41rem) {
  .nav-btn-icon {
    display: none;
  }
  .nav-btn {
    display: none;
  }
  .nav-menu {
    display: flex;
    position: absolute;
    right: 0;
    max-height: none;
    padding-top: 0.3rem;
  }
  .nav-menu li {
    float: left;
    margin: 0.15rem 0.5rem;
  }
  .brand {
    left: auto;
    transform: none;
  }
}

@media only screen and (min-width: 64rem) {
  .nav-btn-icon {
    display: none;
  }
  .nav-menu {
    display: flex;
    position: absolute;
    right: 0;
    max-height: none;
  }
  .nav-menu li {
    float: left;
    margin: 0.15rem 0.5rem;
  }
  .brand {
    left: 50%;
    transform: translate(-50%);
  }
}

/* <Clock /> */

.interactive-component {
  z-index: 5;
  position: relative;
}

.fade {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #111;
  z-index: 4;
  opacity: 1;
  transition: all 1s ease;
}

@media only screen and (max-width: 30rem) {
  .hide-header {
    display: none;
  }
}

.sticky {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #111;
}

.clock-container {
  text-align: center;
  margin-top: 1.6875rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timer-container {
  position: relative;
  width: 25rem;
  height: 25rem;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-circle {
  position: absolute;
  width: 100%;
  height: 100%;
}

.circleElement {
  transition: stroke-dashoffset 1s linear;
}

.clock-time {
  position: relative;
  font-size: 6rem;
}

@media (max-width: 600px) {
  .timer-container {
    width: 90vw; /* Adjust the width to fit the viewport */
    height: 90vw; /* Maintain the aspect ratio */
  }
  .clock-time {
    font-size: calc(6rem * 0.8);
  }
}

.clock-btns {
  border: none;
  background: none;
  font-size: 1rem;
  margin: 0 0.7rem 0 0.7rem;
  padding: 0;
  transition-duration: 0.3s;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .clock-btns:hover,
  .tab-btn:hover {
    color: #cb2b4b;
  }
}

.clock-btns:active {
  background: rgba(184, 184, 184, 0.15);
  border-bottom: none;
  position: relative;
  top: 0.125rem;
}

.clock-container .active-clock {
  border-bottom: 0.125rem solid #cb2b4b;
}

.clock-container .active-clock:active {
  border-bottom: none;
}

.time-btns-container {
  display: flex;
  align-items: center;
}

.time-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.8rem;
  height: 2.8rem;
  border: solid 0.15rem #d8d8d8;
  cursor: pointer;
  background: rgba(184, 184, 184, 0);
  transition: background-color 0.2s;
}

.time-btns:nth-of-type(2) {
  width: 4rem;
  height: 4rem;
  margin-right: 1.75rem;
  margin-left: 1.75rem;
}

@media (hover: hover) and (pointer: fine) {
  .time-btns:hover {
    background: #cb2b4bab;
  }
}

.time-btns:active {
  background: rgba(184, 184, 184, 0.15);
  position: relative;
  transform: scale(0.95);
}

.skip-breaks-container {
  position: relative;
  text-align: center;
  font-size: 0.95rem;
  margin: 1.2rem 0 0 0;
}

.skip-breaks-lbl {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.skip-breaks-lbl > span {
  padding-left: 0.25rem;
}

.skip-breaks-lbl > input {
  height: 1rem;
  width: 1rem;
  appearance: none;
  border: 0.125rem solid #cb2b4b;
  border-radius: 0.22rem;
  transition-duration: 0.3s;
  cursor: pointer;
}

.skip-breaks-lbl > input:active {
  border: 0.125rem solid rgba(184, 184, 184, 0.15);
}

.skip-breaks-lbl > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  position: absolute;
  left: 0.35rem;
  bottom: 0.05rem;
}

.active-task-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.2rem;
}

.active-task-description > span:nth-child(1) {
  font-size: 1.4rem;
}

.active-task-description > span:nth-child(2) {
  font-size: 1.1rem;
}

/* <TaskList /> */

.tab-container {
  width: 95%;
  max-width: 37.5rem;
  margin: 3rem auto 1rem auto;
  border-radius: 0.44rem;
  overflow: hidden;
}

.tab-btns-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.tab-btn {
  text-align: center;
  border: none;
  cursor: pointer;
  margin: 0 1rem;
  padding: 0;
  background: rgba(255, 255, 255, 0);
  font-size: 1.25rem;
  transition: color 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .add-task-btn:hover {
    background: #cb2b4bab;
  }
}

.tab-content {
  padding: 0rem 0.6rem 0.375rem 0.6rem;
  border-top: 0;
}

.tab-content span:nth-child(1) {
  font-size: 1rem;
  word-break: break-word;
}

@media only screen and (min-width: 40rem) {
  .tab-content span:nth-child(1) {
    font-size: 1.25rem;
  }
}

.active-tab {
  border-bottom: 0.125rem solid #cb2b4b;
}

.task-container button {
  border: none;
  border-radius: 0.22rem;
  background: rgba(255, 255, 255, 0);

  color: #d8d8d8;
  transition: background-color 0.2s;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.task-container .add-task-btn {
  outline: dashed 0.125rem #dfdfdfa0;
  color: #dfdfdfa0;
  font-size: 1.25rem;
  padding: 1.12rem;
}

@media (hover: hover) and (pointer: fine) {
  .task-container button:hover {
    background: #cb2b4bab;
  }
}

@media (hover: hover) and (pointer: fine) {
  .task-output:hover:not(.active-task-output):not(
      .completed-task-page .task-output
    ) {
    transform: translateX(0.35rem);
  }
}

.task-container form {
  display: flex;
  font-size: 0.88rem;
  align-items: center;
  padding: 0.54rem 0.5rem 0.54rem 0.5rem;
  border-radius: 0.22rem;
  outline: solid thin;
}

.add-task-btn {
  display: flex;
  width: 100%;
  transition: background-color 0.2s;
  justify-content: center;
  padding: 0.4rem;
}

.icon-add-square {
  text-align: center;
  width: 1.3rem;
  height: 1.3rem;
  margin-top: 0.25rem;
  margin-right: 0.1875rem;
}

.add-task-icon-primary {
  fill: rgba(255, 255, 235, 0);
  outline: solid thin;
  border-radius: 0.22rem;
}

.add-task-icon-secondary {
  fill: #dfdfdfa0;
}

.add-task-name-ipt {
  min-width: 0;
  margin: 0.4rem 0.5rem;
  margin-left: 0;
  padding: 0.5rem;
  flex-grow: 2;
  border: none;
  border-radius: 0.22rem;
  background: #d8d8d8;
  font-size: 1rem;
}

.add-task-name-ipt::placeholder {
  font-style: italic;
  color: rgba(7, 26, 41, 0.6);
}

.add-task-interval-ipt {
  width: 2rem;
  margin: 0.4rem 0.5rem;
  padding: 0.5rem;
  border: 0;
  border-radius: 0.22rem;
  background: #d8d8d8;
  font-size: 1.05rem;
}

.toggle-hide-btn {
  display: none;
}

#toggle-hide-form {
  display: none;
}

.task-output {
  display: flex;
  padding: 0.5rem;
  border-radius: 0.22rem;
  outline: solid thin;
  transition: all 0.3s ease;
}

.task-output:not(.active-task-output):not(.completed-task-page .task-output) {
  cursor: pointer;
}

.active-task-output {
  background: rgba(184, 184, 184, 0.15);
}

.task-output > span,
.task-output,
.task-container form {
  margin: 0.5rem 0 0.5rem 0;
}

.task-output span:nth-child(1) {
  flex-grow: 2;
  align-self: center;
}

.task-output-btns {
  margin-left: 0.35rem;
  height: 50%;
  align-self: center;
  padding-bottom: 0;
}

.task-output-svg-icons {
  fill: black;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
}

.task-output-icons-primary {
  fill: #d8d8d8;
}

.task-output-icons-secondary {
  fill: #d8d8d8;
}

#task-output-datestamp {
  width: 5.55rem;
  align-self: stretch;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  border-left: 0.125rem solid rgba(184, 184, 184, 0.3);
  border-right: 0.125rem solid rgba(184, 184, 184, 0.3);
}

#task-output-interval {
  width: 1.79375rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
}

.completed-task-page {
  display: none;
}

.active-completed-task-page {
  display: block;
}

.completed-task-page-btns {
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
}

.completed-task-page-btns button {
  border: none;
  border-radius: 0.22rem;
  padding: 0.25rem 0.55rem;
  background: rgba(184, 184, 184, 0.15);
  color: #d8d8d8;
  transition: background-color 0.2s;
  cursor: pointer;
}

.completed-task-page-btns span {
  margin: 0 0.6rem;
}

@media (hover: hover) and (pointer: fine) {
  .completed-task-page-btns button:hover {
    background: #cb2b4bab;
  }
}

/* <SettingsModalContainer /> */

.settings-modal-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
}

.settings-modal {
  z-index: 5;
  position: fixed;
  width: 95%;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: #111;
  box-shadow: 0 0 0.2rem 0 #999999;
  border-radius: 0.44rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 40rem) {
  .settings-modal {
    max-width: 25rem;
  }
}

.settings-modal-header {
  padding: 1.5rem 0.625rem 0.625rem 0.625rem;
  text-align: center;
}

.settings-modal-content {
  font-size: 1rem;
  padding: 1.5rem 1rem;
}

.settings-modal-content > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.settings-modal-content input,
.settings-modal-content select {
  width: 4.3rem;
  border-radius: 0.22rem;
  padding: 0.3rem;
  border: none;
  border-radius: 0.22rem;
  background: rgba(235, 235, 235, 0.95);
  margin-bottom: 1rem;
}

#alarm-volume {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  align-self: center;
  margin: 0;
  height: 0.5rem;
  width: 10.5rem;
  padding: 0;
  background: #d8d8d8;
}

#alarm-volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background: #cb2b4b;
}

#alarm-volume::-moz-range-thumb {
  height: 1.2rem;
  width: 1rem;
  border-radius: 50%;
  background: #cb2b4b;
}

.settings-modal-content select {
  width: 4.8rem;
}

.alarm-volume-num {
  width: 2rem;
  text-align: center;
}

.settings-duration-header {
  text-align: center;
  margin-bottom: 0.4rem;
}

.settings-duration {
  display: flex;
  text-align: center;
  margin-top: 0.3125rem;
}

.settings-duration label {
  font-size: 0.9rem;
}

.settings-duration > label > input {
  margin-top: 0.3rem;
}

.settings-other {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 95%;
}

.settings-other > div {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.settings-modal-footer {
  background: rgba(7, 26, 41, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.settings-confirm-btn {
  margin: 1.2rem 0.75rem 0.3rem 0.75rem;
  background: rgba(184, 184, 184, 0.3);
  padding: 0.625rem 0;
  border: none;
  color: white;
  border-radius: 0.22rem;
  cursor: pointer;
  font-size: 1.05rem;
  transition: background-color 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .settings-confirm-btn:hover {
    background: #cb2b4bab;
  }
}

.settings-confirm-btn:active {
  background: rgba(184, 184, 184, 0.15);
}

/* <MetricsModalContainer /> */

.metrics-modal-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
}

.metrics-modal {
  z-index: 5;
  position: absolute;
  width: 95%;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: #111;
  box-shadow: 0 0 0.2rem 0 #999999;
  border-radius: 0.44rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 40rem) {
  .metrics-modal {
    max-width: 38rem;
  }
}

.metrics-modal-header {
  border-radius: inherit;
  padding: 1.5rem 0.625rem 0.625rem 0.625rem;
  text-align: center;
}

.metrics-modal-content {
  font-size: 1rem;
  padding: 1.5rem 0.75rem 0.75rem 0.75rem;
}

.metrics-date-btn {
  border: none;
  border-radius: 0.22rem;
  background: rgba(184, 184, 184, 0.15);
  color: #d8d8d8;
  padding: 0.4rem;
  transition: background-color 0.2s;
  cursor: pointer;
}

.metrics-date-type-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.metrics-date-type-container button {
  border: none;
  background: none;
  color: #d8d8d8;
  font-size: 1rem;
  margin: 0 0.7rem 0 0.7rem;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .metrics-date-type-container button:hover {
    color: #cb2b4b;
  }
}

.metrics-date-type-container button:active {
  background: rgba(184, 184, 184, 0.15);
  border-bottom: none;
  position: relative;
  top: 0.125rem;
}

.metrics-date-type-container button + button {
  margin-left: 0.5rem;
}

.metrics-date-type-container .metrics-date-type-active {
  border-bottom: 0.125rem solid #cb2b4b;
}

.metrics-date-range-container {
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
}

.metrics-date-range-container button {
  border: none;
  border-radius: 0.22rem;
  background: rgba(184, 184, 184, 0.15);
  color: #d8d8d8;
  transition: background-color 0.2s;
  cursor: pointer;
}

.metrics-date-range-container span {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.metrics-modal-footer {
  padding: 0.75rem 1.5rem 1.5rem 1.5rem;
  border-radius: inherit;
}

.metrics-modal-footer > div {
  display: flex;
  justify-content: space-between;
  font-size: 1.0625rem;
}

.metrics-summary-titles {
  color: #cb2b4b;
  font-size: 1.2rem;
}

.metrics-summary-total {
  border-top: 0.0625rem solid #d8d8d8;
  min-width: 5rem;
  text-align: right;
}

.metrics-zzz {
  display: none;
}

.metrics-local {
  text-align: center;
}

/* <Login /> */

.login-modal-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 8;
}
.login-modal {
  z-index: 9;
  position: fixed;
  width: 95%;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: #111;
  box-shadow: 0 0 0.2rem 0 #999999;
  border-radius: 0.44rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 40rem) {
  .login-modal {
    max-width: 25rem;
  }
}
.login-modal-header {
  padding: 1.5rem 0.625rem 0.625rem 0.625rem;
  text-align: center;
}
.login-modal-content {
  font-size: 1.05rem;
}
.login-modal-content input {
  width: 100%;
  border-radius: 0.22rem;
  padding: 0.76703125rem 0 0.76703125rem 0;
  border: none;
  border-radius: 0.22rem;
  background: rgba(235, 235, 235, 0.95);
  margin-bottom: 1rem;
  font-size: 1.05rem;
  text-indent: 0.76703125rem;
}
.login-modal-content .auth-email-btn {
  padding: 0.625rem;
  border: none;
  color: white;
  border-radius: 0.22rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.05rem;
  width: 100%;
  transition: background-color 0.2s;
}
@media (hover: hover) and (pointer: fine) {
  .login-modal-content .auth-email-btn:hover {
    background: #cb2b4bab;
  }
}

/* <AuthLogin /> <AuthRegister /> <AuthReset /> */

.auth-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.5rem 1.875rem;
  vertical-align: center;
}
.auth-email-btn {
  background: rgba(184, 184, 184, 0.3);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.25rem 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.0625rem solid #d8d8d8;
}

.separator::before {
  margin-right: 0.625rem;
}

.separator::after {
  margin-left: 0.625rem;
}

.separator span {
  padding: 0 0.625rem;
  font-weight: bold;
}

.auth-forgot-text {
  margin-top: 0.5rem;
}

.auth-link-text {
  margin-top: 1.5rem;
}
.auth-links:link,
.auth-links:visited {
  color: rgb(47, 122, 252);
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 1rem;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.gsi-material-button-signout {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button-signout .gsi-material-button-icon-signout {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button-signout .gsi-material-button-profile-signout {
  height: 25px;
  margin-right: 12px;
  min-width: 25px;
  width: 25px;
  border-radius: 50%;
}

.gsi-material-button-signout .gsi-material-button-content-wrapper-signout {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button-signout .gsi-material-button-contents-signout {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button-signout .gsi-material-button-state-signout {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button-signout:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button-signout:disabled .gsi-material-button-contents-signout {
  opacity: 38%;
}

.gsi-material-button-signout:disabled .gsi-material-button-icon-signout {
  opacity: 38%;
}

.gsi-material-button-signout:not(:disabled):active
  .gsi-material-button-state-signout,
.gsi-material-button-signout:not(:disabled):focus
  .gsi-material-button-state-signout {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button-signout:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button-signout:not(:disabled):hover
  .gsi-material-button-state-signout {
  background-color: #303030;
  opacity: 8%;
}

/* <Guide /> */

.guide-container {
  background: #d1d1d1;
  display: flex;
  flex-direction: column;
}

.guide-btn-container {
  background: #111;
  padding-bottom: 0.5rem;
  text-align: center;
}

.guide-btn-container button {
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
}

@media (hover: hover) and (pointer: fine) {
  .guide-btn-container button:hover {
    color: #cb2b4b;
  }
}

.guide {
  color: #111;
  margin: 1.5rem auto 0 auto;
  padding: 1rem 1rem;
  max-width: 40rem;
}

.guide-toggle-hide {
  display: none;
}

.guide h2 {
  font-size: 1.38rem;
  margin-bottom: 0;
  margin-top: 0;
}

.pomodoro-guide {
  margin-top: 1.7rem;
}

.guide p {
  font-size: 1rem;
  margin: 0;
  margin-top: 1rem;
}

/* <PageFooter /> */

footer {
  padding: 1rem 0;
  margin-top: 0;
  background: #d1d1d1;
  color: #111;
}

.hide-footer {
  display: none;
}

.footer-black {
  background: #111;
  color: #d8d8d8;
}

.footer-btns {
  display: flex;
  justify-content: center;
  margin: 0 0 0.5rem 0;
}

.footer-btns > button,
.footer-btns > a {
  font-size: 1.2rem;
  margin: 0;
  padding: 0 0.5rem;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  transition: color 0.2s ease-out;
  text-decoration: none;
}

.footer-btns > button:first-child::after,
.footer-btns > a:first-child::after {
  content: '';
  display: inline-block;
  height: 0.9rem;
  width: 0.0625rem;
  left: 0.5rem;
  position: relative;
  background: #111;
  pointer-events: none;
}

.footer-btns > button:nth-child(2)::after,
.footer-btns > a:nth-child(2)::after {
  content: '';
  display: inline-block;
  height: 0.9rem;
  width: 0.0625rem;
  left: 0.5rem;
  position: relative;
  background: #111;
  pointer-events: none;
}

.footer-black .footer-btns > button:first-child::after,
.footer-black .footer-btns > a:first-child::after {
  content: '';
  display: inline-block;
  height: 0.9rem;
  width: 0.0625rem;
  left: 0.5rem;
  position: relative;
  background: #d8d8d8;
  pointer-events: none;
}

.footer-black .footer-btns > button:nth-child(2)::after,
.footer-black .footer-btns > a:nth-child(2)::after {
  content: '';
  display: inline-block;
  height: 0.9rem;
  width: 0.0625rem;
  left: 0.5rem;
  position: relative;
  background: #d8d8d8;
  pointer-events: none;
}

@media (hover: hover) and (pointer: fine) {
  .footer-btns > button:hover {
    color: #cb2b4b;
  }

  .footer-btns > a:hover {
    color: #cb2b4b;
  }
}

.copyright {
  font-size: 0.85rem;
  text-align: center;
}

/* <ContactModalContainer /> */

.contact-modal-shadow {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
}

.contact-modal {
  z-index: 5;
  position: fixed;
  width: 95%;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: #111;
  box-shadow: 0 0 0.2rem 0 #999999;
  border-radius: 0.44rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

@media only screen and (min-width: 40rem) {
  .contact-modal {
    max-width: 25rem;
  }
}

.contact-modal-header {
  background: rgba(7, 26, 41, 1);
  padding: 0.625rem;
  text-align: center;
}

.contact-modal-content {
  font-size: 1.05rem;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
}

.contact-modal-content input,
.contact-modal-content textarea {
  width: 100%;
  border-radius: 0.22rem;
  padding: 0.76703125rem 0 0.76703125rem 0;
  border: none;
  border-radius: 0.22rem;
  background: rgba(235, 235, 235, 0.95);
  margin-bottom: 1rem;
  font-size: 1.05rem;
  text-indent: 0.76703125rem;
  font-family: sans-serif;
}

.contact-modal-content textarea {
  min-height: 7rem;
  margin-bottom: 0.6rem;
}

.contact-modal-content button {
  margin: 0;
  padding: 0.625rem;
  width: 100%;
  border: none;
  color: white;
  border-radius: 0.22rem;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.05rem;
  background: rgba(184, 184, 184, 0.3);
}

.contact-modal-footer {
  background: rgba(7, 26, 41, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (hover: hover) and (pointer: fine) {
  .contact-submit-btn:hover {
    background: #cb2b4bab;
  }
}

/* <Privacy /> */

.privacy {
  color: #111;
  max-width: 40rem;
  margin: 1.5rem auto 0 auto;
  padding: 0 1rem 1rem 1rem;
}

.privacy p:last-child {
  margin-bottom: 0;
}

.privacy-header {
  padding: 1.5rem 0;
}

.privacy-content h1 {
  font-size: 1.7rem;
  padding: 0;
}

.privacy-content h2 {
  font-size: 1.5rem;
}

.privacy-content p {
  font-size: 1.2rem;
}
